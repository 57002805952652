import { Component, OnChanges, computed, inject, input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { DialogIncidentComponent } from "../../dialogs/dialog-incident/dialog-incident";
import { Instance } from "../../models/instance";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { StatusToIconPipe } from "../../../helpers/statusiconpipe/statusiconpipe";
import { AliasEditorComponent } from "../../common/alias-editor/alias-editor.component";
import { ServiceStatusComponent } from "../service-status/service-status";
import { PortVisualiser } from "../../subscription-detail/port-visualiser/port-visualiser";
import { ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";
import { HealthIndicator } from "../../models/HealthIndicator";
import { ServiceSubtitleComponent } from "../service-subtitle/service-subtitle";
import { ServiceElementTagsComponent } from "../service-element-tags/service-element-tags";

@Component({
  selector: "service-element-simple",
  templateUrl: "service-element-simple.html",
  imports: [
    AliasEditorComponent,
    AngularSvgIconModule,
    CommonModule,
    PortVisualiser,
    ReadableSizePipe,
    RouterModule,
    ServiceElementTagsComponent,
    ServiceStatusComponent,
    StatusToIconPipe,
    TranslateModule,
    ServiceSubtitleComponent,
  ],
})
export class ServiceElementSimple implements OnChanges {
  private dialog = inject(MatDialog);
  private subscriptionService = inject(SubscriptionService);

  protected subscription = input.required<Instance>();
  protected productType = input.required<string>();

  protected subscriptionId = computed(() => this.subscription().subscriptionId);
  protected customerDescription = computed(() => this.subscription().customerDescription);
  protected firewallEnabled = computed(() => this.subscription().firewallEnabled);
  protected tags = computed(() => this.subscription().tags || []);

  private isPort = computed(() => this.productType() === "Port");
  protected health = signal<HealthIndicator | undefined>(undefined);
  protected traffic = computed(() => this.health()?.traffic);

  protected showPortVisualizer = computed(() => this.isPort() && !this.subscription().tags?.includes("LINK_MEMBER"));

  ngOnChanges() {
    // have not fetched health before, but we have a subscription
    if (!this.health() && this.subscriptionId()) {
      this.loadHealth();
    }
  }

  loadHealth() {
    this.subscriptionService.getHealthDataForSubscription(this.subscriptionId()).then((healthData) => {
      this.health.set(healthData);
    });
  }

  openIncidentDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIncidentComponent, {
      data: {
        subscription: this.subscription(),
      },
    });
    return false;
  }

  getRouterLink() {
    return getDetailRouterLink(this.subscription(), this.productType());
  }
}
