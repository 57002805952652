import { Component, OnChanges, computed, inject, input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { DialogIncidentComponent } from "../../dialogs/dialog-incident/dialog-incident";
import { Instance } from "../../models/instance";
import { knownVendors } from "../../../helpers/kpi/kpi-item";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";
import { TranslateModule } from "@ngx-translate/core";
import { LoaderComponent } from "../../common/loader/loader";
import { RouterModule } from "@angular/router";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AliasEditorComponent } from "../../common/alias-editor/alias-editor.component";
import { KpiRowComponent } from "../kpi-row/kpi-row";
import { ServiceElementTagsComponent } from "../service-element-tags/service-element-tags";
import { ServiceSubtitleComponent } from "../service-subtitle/service-subtitle";

@Component({
  selector: "wireless-element",
  templateUrl: "wireless-element.html",
  imports: [
    AliasEditorComponent,
    AngularSvgIconModule,
    KpiRowComponent,
    LoaderComponent,
    RouterModule,
    ServiceElementTagsComponent,
    TranslateModule,
    ServiceSubtitleComponent,
  ],
})
export class WirelessElementComponent implements OnChanges {
  private dialog = inject(MatDialog);
  private subscriptionService = inject(SubscriptionService);

  protected subscription = input.required<Instance>();
  protected productType = input.required<string>();

  protected sensorPresent = computed(() => this.subscription().sensorPresent);
  protected subscriptionId = computed(() => this.subscription().subscriptionId);
  protected customerDescription = computed(() => this.subscription().customerDescription);
  protected tags = computed(() => this.subscription().tags || []);

  private location = computed(() => this.subscription().location);
  protected activeAps = computed(() => this.location().activeAccessPoints);
  protected inactiveAps = computed(() => this.location().inactiveAccessPoints);

  protected kpiList: {
    kpiAverages: { [key: string]: number }[];
  };

  get wirelessVendor(): string | undefined {
    const serviceTags: string[] = this.tags().map((tag) => tag.toLowerCase());
    return serviceTags.filter((tag) => knownVendors.includes(tag)).at(0);
  }

  ngOnChanges() {
    this.subscriptionService.getWirelessListKpiForSubscription(this.subscriptionId()).then((kpiList) => {
      this.kpiList = kpiList;
    });
  }

  openIncidentDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIncidentComponent, {
      data: {
        subscription: this.subscription(),
      },
    });
    return false;
  }

  getRouterLink() {
    return getDetailRouterLink(this.subscription(), this.productType());
  }
}
