<div class="dialog">
  <div class="dialog__header">
    <h3 class="dialog__title">{{ "Domains.ReportIssue.Title" | translate }}</h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" mat-dialog-close></svg-icon>
  </div>
  <form #actorForm="ngForm" class="form--flexgrow">
    @if (isResultState()) {
      <div></div>
      <dialog-result-state [positive]="!isErrorState()">
        @if (!isErrorState()) {
          <div>
            <h3>{{ "Services.Fulfilment.ReportSuccessful" | translate }}</h3>
            <p></p>
          </div>
        } @else {
          <div>
            <h3>{{ "Services.Fulfilment.ReportFailed" | translate }}</h3>
            <p></p>
          </div>
        }
      </dialog-result-state>
    } @else {
      <div class="dialog__body--grow">
        <div class="dialog__inner">
          <div class="dialog__mb-2">
            <div class="dialog-option-available__item-right dialog-option-available__item-right--flex">
              <div>
                <label for="issueType" class="form-label">{{
                  "Domains.ReportIssue.SelectIssueType" | translate
                }}</label>
                <div class="select-wrapper">
                  <select [(ngModel)]="issueType" class="select" id="issueType" name="issueType">
                    @for (type of ISSUE_TYPES; track $index) {
                      <option [value]="type" [selected]="issueTypeIs(type)">
                        {{ "Domains.ReportIssue.IssueType." + type | translate }}
                      </option>
                    }
                  </select>
                  <svg-icon src="assets/images/icons/select-arrows.svg"></svg-icon>
                </div>
              </div>
              <div style="flex-grow: 1">
                <div class="input__wrapper">
                  <label for="specificObject" class="form-label">{{
                    "Domains.ReportIssue.SpecificObject" | translate
                  }}</label>
                  <input
                    [(ngModel)]="specificObject"
                    class="input"
                    id="specificObject"
                    type="text"
                    name="specificObject"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="dialog__mb-2">
            <div class="textarea__wrapper">
              <label for="description" class="form-label">{{ "Domains.ReportIssue.Description" | translate }}</label>
              <textarea
                [(ngModel)]="description"
                name="description"
                class="textarea"
                id="description"
                placeholder="{{ 'Domains.ReportIssue.Description.Placeholder' | translate }}"
                rows="5"
                minlength="5"
                required
                #fctldescription="ngModel"
              ></textarea>
              @if (fctldescription.hasError("required")) {
                <span class="input__error--small">{{ "Form.InputError.Required" | translate }}</span>
              } @else if (fctldescription.hasError("minlength")) {
                <span class="input__error--small">{{ "Form.InputError.MinLength" | translate }}</span>
              }
            </div>
          </div>

          <h3 class="dialog__sub-heading">{{ "Domains.ReportIssue.ContactInfo" | translate }}</h3>
          <div class="dialog-option-available__item-right dialog-option-available__item-right--flex">
            <div class="input__wrapper">
              <label for="fullname" class="form-label">{{ "Domains.ReportIssue.FullName" | translate }}</label>
              <input
                [(ngModel)]="fullname"
                required
                class="input"
                id="fullname"
                type="text"
                name="fullname"
                #fctlname="ngModel"
              />
              @if (fctlname.hasError("required")) {
                <span class="input__error--small">{{ "Form.InputError.Required" | translate }}</span>
              }
            </div>

            <div class="input__wrapper">
              <label for="email" class="form-label">{{ "Domains.ReportIssue.Email" | translate }}</label>
              <input
                [(ngModel)]="email"
                required
                email
                class="input"
                id="email"
                type="text"
                name="email"
                #fctlemail="ngModel"
              />
              @if (fctlemail.hasError("required")) {
                <span class="input__error--small">{{ "Form.InputError.Required" | translate }}</span>
              } @else if (fctlemail.hasError("email")) {
                <span class="input__error--small">{{ "Form.InputError.Invalid" | translate }}</span>
              }
            </div>

            <div class="input__wrapper">
              <label for="contactOther" class="form-label">{{ "Domains.ReportIssue.ContactOther" | translate }}</label>
              <input [(ngModel)]="contactOther" class="input" id="contactOther" name="contactOther" type="text" />
            </div>
          </div>
        </div>
      </div>
    }

    <div class="dialog__footer dialog__footer--center dialog__footer--border">
      <div></div>
      <div class="button__flex">
        @if (isResultState()) {
          <button class="button button--primary" mat-dialog-close>{{ "Global.Action.Close" | translate }}</button>
        } @else {
          <button class="button button--secondary" mat-dialog-close>{{ "Global.Action.Cancel" | translate }}</button>
          <app-safe-button
            (onClick)="submit()"
            [disabled]="actorForm.form.invalid"
            [small]="false"
            [loaderAfterClick]="true"
            class="button button--primary"
            >{{ "Global.Action.Send" | translate }}</app-safe-button
          >
        }
      </div>
    </div>
  </form>
</div>
