<navigation></navigation>
<div class="container">
  <div class="row">
    <div class="col-4 col-filter">
      <div class="filter">
        <section class="section">
          <div class="input__wrapper">
            <input
              type="search"
              [(ngModel)]="servicesSearch"
              value=""
              class="input input--search"
              name=""
              placeholder="{{ 'Form.Placeholder.SearchTerm' | translate }}"
              (keyup)="searchValueChange($event)"
            />
          </div>
        </section>

        @if (!isWireless()) {
          <filter-by-status />
        }

        <section class="section">
          <h3>{{ "Services.Tags" | translate }}</h3>
          <div class="checktag__flex" *ngIf="collectedTags.length > 0">
            @for (tag of collectedTags; track tag) {
              <checktag checktagTitle="{{ tag }}" />
            }
          </div>
        </section>

        @if (!isWireless()) {
          <section class="section">
            <h3>{{ "Services.Filter.Endpoint" | translate }}</h3>
            <completer-location [locations]="collectedLocations"></completer-location>
          </section>
        }
      </div>
    </div>
    <div class="col-12 col-md-8 col-content">
      @if (filterActive) {
        <section class="section">
          @for (sub of filteredSubscriptions; track sub.subscriptionId) {
            <div class="row">
              <div class="col-12">
                @if (isWireless()) {
                  <wireless-element [subscription]="sub" [productType]="productType()" />
                } @else {
                  <service-element-simple [subscription]="sub" [productType]="productType()"></service-element-simple>
                }
              </div>
            </div>
          }
        </section>
      } @else {
        <section class="section">
          @if (!subscriptions || loadingState.remaining > 0) {
            <loader [detailed]="true" [small]="true" [text]="loadingState.asText"></loader>
          }
          @for (sub of subscriptions; track sub.subscriptionId) {
            <div class="row">
              <div class="col-12">
                @if (isWireless()) {
                  <wireless-element [subscription]="sub" [productType]="productType()" />
                } @else {
                  <service-element-simple [subscription]="sub" [productType]="productType()"></service-element-simple>
                }
              </div>
            </div>
          }
        </section>
      }
    </div>
  </div>
</div>
<div class="container">
  <footer></footer>
</div>
