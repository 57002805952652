import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { FooterComponent } from "../../components/common/footer/footer";
import { MatDialog } from "@angular/material/dialog";
import { DialogDomainReportProblemComponent } from "../../components/dialogs/dialog-domain-report-problem/dialog-domain-report-problem";

@Component({
  selector: "page-support",
  templateUrl: "support.html",
  styleUrls: ["./support.scss"],
  imports: [FooterComponent, NavigationComponent, TranslateModule],
})
export class SupportPage {
  private readonly dialog = inject(MatDialog);
  reportProblem() {
    this.dialog.open(DialogDomainReportProblemComponent, {});
  }
}
