<page [subscription]="subscription" [reportedStatus]="reportedStatus()">
  @if (isLoading()) {
    <loader />
  } @else if (isNotFound()) {
    <not-found />
  } @else if (isNotAuthorized()) {
    <not-authorized />
  } @else if (pageLoaded()) {
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col--flex">
          <l2vpn-shortcut-menu
            [subscription]="subscription"
            [isTerminated]="isTerminated"
            [activities]="activities"
          ></l2vpn-shortcut-menu>
        </div>
        <div class="col-lg-9">
          <div class="card">
            <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

            <div class="row">
              <div class="col-12 col-sm-7">
                <table class="table-properties table-properties--no-margin">
                  <tr>
                    <td>{{ "ServiceElement.Owner" | translate }}</td>
                    <td>{{ owner() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.OwnAlias" | translate }}</td>
                    <td>
                      <alias-editor [customerDescription]="customerDescription()" [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  @if (hasReferences()) {
                    <tr third-party-service-ids-row-content [subscription]="subscription"></tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.SURFnetDescription" | translate }}</td>
                    <td>{{ description() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ServiceID" | translate }}</td>
                    <td>
                      <service-id [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ProductName" | translate }}</td>
                    <td>{{ "EVPN - Multipoint" }} (aka {{ productName() }})</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.Protection" | translate }}</td>
                    <td>{{ protection() }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-sm-5">
                <table class="table-properties table-properties--no-margin">
                  <tr
                    *ngIf="
                      subscription.circuits
                      && subscription.circuits.length > 0
                      && subscription.circuits[0].speedPolicer !== null
                    "
                  >
                    <td>{{ "ServiceElement.SpeedPolicer" | translate }}</td>
                    <td>
                      <span
                        *ngIf="
                          auth.isSelfServiceEnabled
                            && auth?.hasRole(['Infraverantwoordelijke'])
                            && auth?.isCurrentOrganisation(subscription.customerId);
                          else readOnly
                        "
                        class="table-properties__flex"
                        [ngClass]="{ 'table-properties__flex--blue': speedPolicer() }"
                      >
                        {{ speedPolicer() | enabled_or_value }}
                        @if (!subscription.firewallEnabled) {
                          <selfservice-toggle
                            [active]="speedPolicer()"
                            (click)="openSelfServiceDialog('sp')"
                          ></selfservice-toggle>
                        }
                      </span>
                      <ng-template #readOnly>
                        {{ speedPolicer() | enabled_or_value }}
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ServiceSpeed" | translate }}</td>
                    <td>{{ serviceSpeed() | service_speed }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.Domain" | translate }}</td>
                    <td>{{ domain() }}</td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.Status" | translate }}</td>
                      <td>{{ status() }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Start" | translate }}</td>
                    <td>{{ startDate() | subscription_date }}</td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.End" | translate }}</td>
                      <td>{{ endDate() | subscription_date }}</td>
                    </tr>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      @if (isTerminated) {
        <div class="hide-everything-after-this-div"></div>
      }

      <page-tab [activeTab]="activeTab()" [productType]="productType()" (activeTabEmitter)="changeActiveTab($event)" />

      @if (isGraphicViewTabActive()) {
        <div>
          <networkgraph [topology]="topology()" #chart></networkgraph>
        </div>
      } @else if (isConfigTabActive()) {
        <div>
          <div class="section">
            <tabbed-detail-container
              [service]="subscription"
              [bus]="messageBus"
              [selfserviceState]="undefined"
              [canModify]="true"
            />
          </div>
          <linechart id="chart" #chart [subscription]="subscription" />
        </div>
      }
    </div>
  }
</page>
