import { Component, computed, input, OnChanges, SimpleChanges } from "@angular/core";
import { KpiItem, KPI_ITEMS_AEROHIVE, KPI_ITEMS_ARUBA, getStatusColor } from "../../../helpers/kpi/kpi-item";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommonModule } from "@angular/common";

@Component({
  selector: "kpi-row",
  templateUrl: "kpi-row.html",
  styleUrls: ["kpi-row.scss"],
  imports: [CommonModule, MatTooltipModule],
})
export class KpiRowComponent implements OnChanges {
  protected healthData = input.required<{ kpiAverages: { [key: string]: number }[] }>();
  protected vendor = input.required<string>();

  protected kpiAverages = computed(() => this.healthData().kpiAverages);
  protected kpiValues: { value: number; item: KpiItem }[] = [];

  statusColor = getStatusColor;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["healthData"]) {
      if (this.healthData() && this.kpiAverages()) {
        this.updateKpiAverages(this.kpiAverages());
      }
    }
  }

  updateKpiAverages(kpiAverages: { [key: string]: number }[]) {
    this.kpiValues = this.getKpiItems().map((kpiItem) => {
      let kpiValue = kpiAverages[kpiItem.key];
      if (!kpiItem.percentage) {
        kpiValue = parseFloat(kpiValue).toFixed(2);
      }

      return { value: kpiValue, item: kpiItem };
    });
  }

  getKpiItems(): KpiItem[] {
    const vendor = this.vendor()?.toLowerCase()?.trim()?.replace(" ", "-");
    if (vendor === "aerohive") {
      return KPI_ITEMS_AEROHIVE;
    } else if (vendor === "aruba") {
      return KPI_ITEMS_ARUBA;
    } else {
      console.warn(`Unknown wifi vendor: ${vendor}.`);
      return [];
    }
  }
}
