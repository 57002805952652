<div class="card card--no-padding service service--clickable" [routerLink]="[getRouterLink()]">
  <div class="service__header">
    <div class="service__left">
      <svg-icon src="assets/images/icons/status-{{ health()?.status | status_to_icon }}.svg"></svg-icon>
      <div class="service__header-caption">
        <alias-editor
          [customerDescription]="customerDescription()"
          [subscriptionId]="subscriptionId()"
          class="list-alias-editor"
        />
        <service-subtitle [subscription]="subscription()" />
        <service-element-tags [firewallEnabled]="firewallEnabled()" [tags]="tags()" />
      </div>
    </div>
    <div class="service__right">
      <div class="service__button" (click)="openIncidentDialog($event)">
        {{ "Services.Fulfilment.ReportProblem" | translate }}
      </div>
      @if (traffic()) {
        <div class="service__current">
          <div class="service__current-col">
            <div class="service__current-title">{{ "ServiceElement.Chart.NowIn" | translate }}</div>
            <div>{{ traffic().last.in | readable_size }}</div>
          </div>
          <div class="service__current-col">
            <div class="service__current-title">{{ "ServiceElement.Chart.NowOut" | translate }}</div>
            <div>{{ traffic().last.out | readable_size }}</div>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="service__graph">
    @if (showPortVisualizer()) {
      <port-visualiser [subscription]="subscription()" />
    }
  </div>

  <service-status class="service__status" productType="{{ productType() }}" subscriptionId="{{ subscriptionId() }}">
  </service-status>
</div>
