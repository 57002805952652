import { Component, computed, input } from "@angular/core";
import { NavigationComponent } from "../common/navigation/navigation";
import { MinimalSubscription } from "../../pages/subscription-detail/components/generic-subscription";
import { FooterComponent } from "../common/footer/footer";

@Component({
  selector: "page",
  templateUrl: "page.html",
  imports: [FooterComponent, NavigationComponent],
})
export class PageComponent {
  protected subscription = input.required<MinimalSubscription | undefined>();
  protected reportedStatus = input("");

  private status = computed(() => (this.reportedStatus() !== "" ? this.reportedStatus() : this.subscription()?.status));
  private title = computed(() => this.subscription()?.customerDescription || "");

  protected detailData = computed(() => ({ title: this.title(), status: this.status() }));
  protected firewalled = computed(() => this.subscription()?.firewallEnabled ?? false);
  protected productType = computed(() => this.subscription()?.product.type);
  protected terminated = computed(() => this.status() === "terminated");
}
