<page [subscription]="subscription" [reportedStatus]="reportedStatus()">
  @if (isLoading()) {
    <loader />
  } @else if (isNotFound()) {
    <not-found />
  } @else if (isNotAuthorized()) {
    <not-authorized />
  } @else if (pageLoaded()) {
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col--flex">
          <ip-shortcut-menu [subscription]="subscription" [isTerminated]="isTerminated" [activities]="activities" />
        </div>

        <div class="col-lg-9">
          <div class="card">
            <subscription-detail-header [subscription]="subscription" [isTerminated]="isTerminated" />

            <div class="row">
              <div class="col-12 col-sm-7">
                <table class="table-properties table-properties--no-margin">
                  <tr>
                    <td>{{ "ServiceElement.Owner" | translate }}</td>
                    <td>{{ owner() }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.OwnAlias" | translate }}</td>
                    <td>
                      <alias-editor [customerDescription]="customerDescription()" [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.SURFnetDescription" | translate }}</td>
                    <td>{{ description() }}</td>
                  </tr>
                  @if (hasReferences()) {
                    <tr third-party-service-ids-row-content [subscription]="subscription"></tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.ServiceID" | translate }}</td>
                    <td>
                      <service-id [subscriptionId]="subscriptionId()" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ProductName" | translate }}</td>
                    <td>Internet (aka SURFinternet)</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.ServiceSpeed" | translate }}</td>
                    <td>{{ subscription.serviceSpeed | service_speed }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-12 col-sm-5">
                <table class="table-properties table-properties--no-margin">
                  @if (productTag() === "IPBGP") {
                    <tr>
                      <td>{{ "ServiceElement.ASNnummer" | translate }}</td>
                      <td>{{ subscription.ipss.asn }}</td>
                    </tr>
                    <tr>
                      <td>{{ "ServiceElement.SURFASNnummer" | translate }}</td>
                      <td>1103</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.SURFcertfilter" | translate }}</td>
                    <td>
                      <span
                        *ngIf="
                          auth?.isCurrentOrganisation(subscription.customerId)
                            && auth?.hasRole([
                              'Infraverantwoordelijke',
                              'surfcert-kernel',
                              'Beveiligingsverantwoordelijke',
                            ])
                            && !isTerminated
                            && !subscription.firewallEnabled;
                          else readOnly
                        "
                        class="table-properties__flex"
                        [ngClass]="{
                          'table-properties__flex--blue': subscription.ipss.surfcertFilter === 'enabled',
                          'table-properties__flex--disabled': false,
                        }"
                      >
                        {{ subscription.ipss.surfcertFilter | enabled_or_value }}
                        <selfservice-toggle
                          [active]="subscription.ipss.surfcertFilter === 'enabled'"
                          [tooltipText]="'Selfservice.Tooltip.DDos' | translate"
                          (click)="openSelfServiceDialog('ddos')"
                        ></selfservice-toggle>
                      </span>
                      <ng-template #readOnly> {{ subscription.ipss.surfcertFilter | enabled_or_value }} </ng-template>
                    </td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.Status" | translate }}</td>
                      <td>{{ status() }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Start" | translate }}</td>
                    <td>{{ startDate() | subscription_date }}</td>
                  </tr>
                  @if (isTerminated) {
                    <tr>
                      <td>{{ "ServiceElement.End" | translate }}</td>
                      <td>{{ endDate() | subscription_date }}</td>
                    </tr>
                  }
                  <tr>
                    <td>{{ "ServiceElement.Domain" | translate }}</td>
                    <td>{{ subscription.domain }}</td>
                  </tr>
                  <tr>
                    <td>{{ "ServiceElement.RouteringType" | translate }}</td>
                    <td>{{ subscription.ipRoutingType }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      @if (isTerminated) {
        <div class="hide-everything-after-this-div"></div>
      }

      <page-tab [activeTab]="activeTab()" [productType]="productType()" (activeTabEmitter)="changeActiveTab($event)">
      </page-tab>

      @switch (activeTab()) {
        @case (SubscriptionDetailTab.CONFIG) {
          <div>
            <div class="section">
              <tabbed-detail-container [service]="subscription" [bus]="messageBus" [selfserviceState]="undefined" />
            </div>

            <linechart id="chart" #chart [subscription]="subscription" />
          </div>
        }
        @case (SubscriptionDetailTab.GRAPHIC_VIEW) {
          <div>
            <networkgraph [topology]="subscription.topology" #chart></networkgraph>
          </div>
        }
      }
    </div>
  }
</page>
