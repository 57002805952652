import { Component, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogClose } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { AuthService } from "../../../services/AuthService";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SafeButtonComponent } from "../../common/safe-button/safe-button.component";
import { ApiHelper } from "../../../helpers/apihelper";
import { DialogResultState } from "../components/result-state.component";

@Component({
  selector: "dialog-domain-report-problem",
  templateUrl: "./dialog-domain-report-problem.html",
  styleUrls: [
    "../dialog-domain-add/dialog-domain-add.scss",
    "../dialog-domain-add/options/domain-option-available/domain-option-available.scss",
  ],
  imports: [MatDialogClose, TranslateModule, FormsModule, AngularSvgIconModule, SafeButtonComponent, DialogResultState],
})
export class DialogDomainReportProblemComponent {
  private readonly auth = inject(AuthService);
  private readonly api = inject(ApiHelper);

  protected issueType = signal("domain");
  protected description = signal("");
  protected email = signal("");
  protected fullname = signal("");
  protected specificObject = signal("");
  protected contactOther = signal("");
  protected ISSUE_TYPES = ["domain", "reverse_zone", "contact", "nameserver", "zone_template", "other"];

  protected isResultState = signal(false);
  protected isErrorState = signal(false);

  constructor() {
    this.auth.userLoaded.subscribe(() => {
      this.email.set(this.auth.state.currentUser.email);
      this.fullname.set(this.auth.state.currentUser.displayName);
    });
  }

  protected issueTypeIs(t: string): boolean {
    return this.issueType() === t;
  }

  protected submit() {
    const issue = {
      email: this.email(),
      fullname: this.fullname(),
      contact: this.contactOther(),
      type: this.issueType(),
      specificObject: this.specificObject(),
      description: this.description(),
    };

    this.api
      .reportIncidentDomeinen(issue)
      .then(() => {
        // success state
        this.isErrorState.set(false);
      })
      .catch((err) => {
        // error state
        console.error(err);
        this.isErrorState.set(true);
      })
      .finally(() => {
        this.isResultState.set(true);
      });
  }
}
