<div class="card card--no-padding service service--clickable" [routerLink]="[getRouterLink()]">
  <div class="service__header">
    <div class="service__left">
      <svg-icon class="service-icon-wireless" src="assets/images/icons/wireless.svg"></svg-icon>

      <div class="service__header-caption">
        <alias-editor
          [customerDescription]="customerDescription()"
          [subscriptionId]="subscriptionId()"
          class="list-alias-editor"
        />
        <service-subtitle [subscription]="subscription()" />
        <service-element-tags [firewallEnabled]="false" [tags]="tags()" />

        <div class="service__access-points">
          <div class="service__access-points-item">
            <span>{{ "ServiceElement.APsActive" | translate }}</span>
            @if (activeAps() !== null) {
              <span>{{ activeAps() }}</span>
            } @else {
              <span>-</span>
            }
          </div>
          <div class="service__access-points-item">
            <span>{{ "ServiceElement.APsInactive" | translate }}</span>
            @if (inactiveAps() !== null) {
              <span>{{ inactiveAps() }}</span>
            } @else {
              <span>-</span>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="service__right">
      <div class="service__button" (click)="openIncidentDialog($event)">
        {{ "Services.Fulfilment.ReportProblem" | translate }}
      </div>
      <div>
        @switch (sensorPresent()) {
          @case (undefined) {
            <loader [small]="true" [paddingSize]="'small'"></loader>
          }
          @case (true) {
            @if (kpiList !== undefined) {
              <kpi-row [healthData]="kpiList" [vendor]="wirelessVendor"></kpi-row>
            } @else {
              <loader [small]="true" [paddingSize]="'small'"></loader>
            }
          }
          @case (false) {
            <p>No sensor present</p>
          }
          @default {
            <p>Unknown sensor state</p>
          }
        }
      </div>
    </div>
  </div>
</div>
