<div class="service__subtitle">
  <span class="black">{{ subscriptionId() | shorten_guid }}</span>
  -
  <span class="black">{{ "ServiceElement.Owner" | translate }}: {{ owner() }}</span>
  <br />
  @for (location of locations(); track $index) {
    <span class="service__endpoint">
      @if (location.address.city) {
        <span>
          {{ location.address | location_address }}
        </span>
      }
    </span>
  }
</div>
