import { Component, computed, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { GuidPipe } from "../../../helpers/guidpipe/guidpipe";
import { Instance } from "../../models/instance";
import { LocationAddressPipe } from "../location-address-pipe";

@Component({
  selector: "service-subtitle",
  templateUrl: "service-subtitle.html",
  imports: [GuidPipe, LocationAddressPipe, TranslateModule],
})
export class ServiceSubtitleComponent {
  protected subscription = input.required<Instance>();

  protected subscriptionId = computed(() => this.subscription().subscriptionId);
  protected owner = computed(() => this.subscription().organisation || this.subscription().customer?.fullname);
  protected locations = computed(() => this.subscription().locations || []);
}
