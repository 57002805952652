import { Component, computed, EventEmitter, inject, input, OnDestroy, OnInit, output, signal } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { ActivatedRoute, Router } from "@angular/router";
import { SelfserviceDialogType } from "../../../helpers/self-service/models/types";
import * as Apollo from "apollo-angular";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CimQueryImpactTypes, CimQueryStatuses } from "../../../graphql/custom-types";
import dayjs from "dayjs";
import { CIM_SUBSCRIPTION } from "../../../graphql/domain/cim";
import { AuthService } from "../../../services/AuthService";
import { DialogAuthComponent } from "../../../components/dialogs/dialog-auth/dialog-auth";
import { Subscription as RxSubscription } from "rxjs";
import { openSelfServiceDialog } from "../../../components/dialogs/dialog-selfservice/dialog-selfservice.helpers";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "../../../services/messageservice";
import { SubscriptionDetailTab } from "../../../components/subscription-detail/page-tab/tab-types";
import { ReferenceType } from "../../../gql/possible-types-ingestor.ts/graphql";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { HealthIndicator } from "../../../components/models/HealthIndicator";

// prettier-ignore
@Component({
    template: "",
})
export abstract class GenericSubscriptionComponent<
  Q extends Apollo.Query<R, { subscriptionId: string, accessCustomerId: string }>,
  R,
  S extends MinimalSubscription,
> implements OnDestroy, OnInit {
  protected api = inject(ApiHelper);
  protected auth = inject(AuthService);
  protected dialog = inject(MatDialog);
  protected route = inject(ActivatedRoute);
  protected translate = inject(TranslateService);
  protected msgsrv = inject(MessageService);
  protected subscriptionsrv = inject(SubscriptionService);

  readonly activeTab = input<SubscriptionDetailTab>();

  incident = output<string>();
  notification = output<string>();

  public impactSetting = "Never";
  public activities: Notification[] = [];

  public dialogRef: MatDialogRef<MatDialog>; // TODO test

  protected isTerminated: boolean;

  public messageBus: EventEmitter<string> = new EventEmitter();

  protected _subscriptionId = "";

  public selfserviceDialogTypes: SelfserviceDialogType[] = [];

  protected isLoading = computed(() => this.pageStatus() === this.isLoading)
  protected pageLoaded = computed(() => this.pageStatus() === this.pageLoaded)
  protected isNotFound = computed(() => this.pageStatus() === this.isNotFound)
  protected isNotAuthorized = computed(() => this.pageStatus() === this.isNotAuthorized)

  private pageStatus = signal(this.isLoading)

  public subscriptionChange: EventEmitter<S | undefined> = new EventEmitter();
  protected query: Q | undefined = undefined;

  // TODO: once we moved all subscription references to a signal, we can replace the getter/setter by this
  protected _subscription = signal<S | undefined>(undefined)

  protected customerDescription = computed(() => this._subscription().customerDescription);
  protected description = computed(() => this._subscription().description);
  private product = computed(() => this._subscription().product);
  protected productName = computed(() => this.product().name);
  protected productTag = computed(() => this.product().tag);
  protected productType = computed(() => this.product().type);

  protected status = computed(() => this._subscription().status);
  protected startDate = computed(() => this._subscription().startDate);
  protected endDate = computed(() => this._subscription().endDate);
  protected references = computed(() => this._subscription().references);
  protected hasReferences = computed(() => this.references().length > 0);
  protected owner = computed(() => this._subscription().organisation);
  protected subscriptionId = computed(() => this._subscription().subscriptionId);

  protected healthIndicator = signal<HealthIndicator | undefined>(undefined);
  protected reportedStatus = computed(() => this.healthIndicator()?.status ?? this._subscription()?.status ?? "unknown");

  private rxSubscriptions: RxSubscription[] = [];

  constructor(
    protected router?: Router,
  ) {
    this._subscriptionId = this.route.snapshot.paramMap.get("subscriptionId");
  }

  public get subscription(): S | undefined {
    return this._subscription();
  }

  public set subscription(subscription: S) {
    this.isTerminated = subscription.status === "terminated"
    this._subscription.set(subscription);
    this.subscriptionChange.emit(this._subscription());
  }

  ngOnInit() {
    const initSubscription = this.auth.userLoaded.subscribe((loaded) => loaded ? this.refresh() : undefined);
    const refreshSubscription = this.messageBus.subscribe((msg) => msg === "refresh" ? this.refresh() : undefined);
    const rxSubscriptionUpdate = this.msgsrv.subscriptionUpdate$.subscribe((data) => {
      if (this.subscription.subscriptionId == data.subscription.subscriptionId) {
        this.subscription = data.subscription;
      }
    });
    this.rxSubscriptions.push(initSubscription);
    this.rxSubscriptions.push(refreshSubscription);
    this.rxSubscriptions.push(rxSubscriptionUpdate);
  }

  ngOnDestroy() {
    while (this.rxSubscriptions.length) {
      this.rxSubscriptions.pop().unsubscribe();
    }
  }

  /** Notifications **/
  fetchActivities() {
    const endDate = dayjs().add(2, "week").toDate();
    const subscription = this.api.apollo
      .query<{ notifications: Array<Notification> }>({
        query: CIM_SUBSCRIPTION,
        fetchPolicy: "network-only",
        variables: {
          filter: {
            customerId: localStorage.getItem("viewingCustomerGUID"),
            beginTimestamp: new Date(),
            endTimestamp: endDate,
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
            impacts: [
              CimQueryImpactTypes.DOWN,
              CimQueryImpactTypes.REDUCED_REDUNDANCY,
              CimQueryImpactTypes.RESILIENCE_LOSS,
            ],
            subscriptionIds: [this._subscriptionId],
          },
        },
      })
      .subscribe((data) => {
        // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
        this.activities = data.data.notifications;
      });
    this.rxSubscriptions.push(subscription);
  }

  /** Dialog and tab interactivity **/
  changeActiveTab(tabName: SubscriptionDetailTab) {
    this.router.navigate(["/subscription", this.productType(), this._subscriptionId, tabName], {replaceUrl: true });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSelfServiceDialog(type: SelfserviceDialogType) {
    const roleCheckResult = this.auth.checkRoleAccess(this.productType(), "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.selfServiceStarted()) {
      const ssAuthData = { data: { state: false, initialAction: type, selfserviceState: { handler: "generic-subscription" } }};
      this.dialog.open(DialogAuthComponent, ssAuthData);
    } else {
      const ssDialogData = { type, subscription: this.subscription };

      // @ts-ignore FIXME this.subscription is not guaranteed to be of a type required by self-service dialog
      openSelfServiceDialog<S>(this.dialog, ssDialogData);
    }
  }

  /** Other */
  private refresh = async () => {
    this.querySubscription(this.query);
    this.fetchActivities();
  }

  private querySubscription(query: Q) {
    const subscription = query
      .watch({ subscriptionId: this._subscriptionId, accessCustomerId: this.auth.viewingCustomerId })
      .valueChanges.subscribe(
        (result) => {
          this.subscription = this.onQuerySuccess(result);
          this.pageStatus.set(this.pageLoaded)
          this.loadHealth();
        },
        (error: ApolloError) => {
          const errorType = error.cause.extensions["error_type"]
          switch (errorType) {
            case "not_authorized":
              this.pageStatus.set(this.isNotAuthorized)
              break
            case "not_found":
              this.pageStatus.set(this.isNotFound)
              break
          }
        },
      );
    this.rxSubscriptions.push(subscription);
  }

  private async loadHealth() {
    this.subscriptionsrv.getHealthDataForSubscription(this.subscriptionId()).then((healthData) => {
      this.healthIndicator.set(healthData);
    });
  }

  protected abstract onQuerySuccess(result: ApolloQueryResult<R>): S;
}

// TODO replace with a generated subscription
export interface MinimalSubscription {
  subscriptionId: string;
  customerDescription: string;
  organisation?: string;
  description: string;
  firewallEnabled?: boolean;
  status: string;
  startDate?: number;
  endDate?: number;
  product: {
    __typeName?: "ProductType";
    id: any;
    name: string;
    tag: string;
    type: string;
  };
  references: ReferenceType[];
}
