import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";

@Component({
  template: `
    <div class="dialog__body dialog__body--center">
      <div class="result-state-container">
        <img [ngClass]="{ greyscale: !positive() }" src="assets/images/success-illustration.svg" alt="" />
        <ng-content></ng-content>
      </div>
    </div>
  `,
  selector: "dialog-result-state",
  styleUrls: ["./result-state.component.scss"],
  imports: [NgClass],
})
export class DialogResultState {
  protected positive = input(false);
}
